import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const ChambleeLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Thomas"
    institution="Chamblee Medical Clinic"
    referralCode="CHAMBLEE"
  />
)

export default ChambleeLandingPage
